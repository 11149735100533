import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function About({ data }) {
  return (
    <div>
      <Layout title="Policies">
        <section id="policies" className="py-3">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h1>Data Protection Policy </h1>
                <p>
                  <b>1. Definitions</b>
                </p>
                <p>
                  Personal Data is information about a person which is
                  identifiable as being about them. It can be stored
                  electronically or on paper, and includes images and audio
                  recordings, as well as written information.
                </p>
                <p>
                  Data protection is about how we, as a community group, ensure
                  we protect the rights and privacy of individuals, and comply
                  with the law, when collecting, storing, using, amending,
                  sharing, destroying or deleting personal data.
                </p>
                <p>
                  <b>2. Responsibility</b>
                </p>
                <p>
                  Overall responsibility lies with the staff, volunteers, and
                  Trustees of Shanti Aid, who oversee activities and ensure this
                  policy is upheld.
                </p>
                <p>
                  <b>3. Overall, Policy Statement</b>
                </p>
                <p>
                  Shanti Aid needs to keep personal data about its volunteers,
                  staff, trustees, and service users to carry out its day-to-day
                  activities.
                </p>
                <p>
                  We will collect, store, use amend share, destroy, or delete
                  personal data only in ways which protect people’s privacy, and
                  comply with General Data Protection Regulations (GDPR) and
                  other relevant legislation.
                </p>
                <p>
                  We will only collect, store, and use the minimum amount of
                  data that we need for clear purposes, and will not collect,
                  store, or use data we do not need.
                </p>
                <p>We will only collect data, store, or use data for: </p>
                <ul>
                  <li>
                    Purposes that are in our group’s legitimate interests, or:
                  </li>
                  <li>Contracts with the individual data it is. or: </li>
                  <li>To comply with legal obligation or: </li>
                  <li>To protect someone’s life. </li>
                  <li>
                    We will provide individuals with details of the data we have
                    about them when requested by the relevant individual.
                  </li>
                </ul>
                <p>
                  We will delete data if requested by the relevant individual
                  unless we need to keep it for legal reasons.
                </p>
                <p>
                  We will endeavour to keep personal data up to date and
                  accurate.
                </p>
                <p>We will store personal data securely. </p>
                <p>
                  We will keep clear records of the purposes of collecting and
                  holding specific data, to ensure it is only used for these
                  purposes.
                </p>
                <p>
                  We will not share personal data with third parties without the
                  explicit consent of the relevant individual, unless legally
                  required to do so.
                </p>
                <p>
                  To uphold this policy, we will maintain a set of data
                  protection procedures for our volunteers, staff, and Trustees.
                </p>
                <p>This policy will be reviewed every two years. </p>
                <h1>Privacy Policy</h1>
                <p>Shanti Aid is a non profit organisation.</p>
                <p>Website address : https://shanti-aid.org</p>
                <p>Email: admin@shanti-aid.org</p>
                <p>What personal data we collect and why we collect it</p>
                <h2>Comments</h2>
                <p>
                  When visitors leave comments on the site we collect the data
                  shown in the comments form, and also the visitor’s IP address
                  and browser user agent string to help spam detection.
                </p>
                <p>
                  An anonymized string created from your email address (also
                  called a hash) may be provided to the Gravatar service to see
                  if you are using it. The Gravatar service privacy policy is
                  available here: https://automattic.com/privacy/. After
                  approval of your comment, your profile picture is visible to
                  the public in the context of your comment.
                </p>
                <h2>Media</h2>
                <p>
                  If you upload images to the website, you should avoid
                  uploading images with embedded location data (EXIF GPS)
                  included. Visitors to the website can download and extract any
                  location data from images on the website.
                </p>
                <h2>Cookies</h2>
                <p>
                  If you leave a comment on our site you may opt-in to saving
                  your name, email address and website in cookies. These are for
                  your convenience so that you do not have to fill in your
                  details again when you leave another comment. These cookies
                  will last for one year.
                </p>
                <p>
                  If you visit our login page, we will set a temporary cookie to
                  determine if your browser accepts cookies. This cookie
                  contains no personal data and is discarded when you close your
                  browser.
                </p>
                <p>
                  When you log in, we will also set up several cookies to save
                  your login information and your screen display choices. Login
                  cookies last for two days, and screen options cookies last for
                  a year. If you select “Remember Me”, your login will persist
                  for two weeks. If you log out of your account, the login
                  cookies will be removed.
                </p>
                <p>
                  If you edit or publish an article, an additional cookie will
                  be saved in your browser. This cookie includes no personal
                  data and simply indicates the post ID of the article you just
                  edited. It expires after 1 day.
                </p>
                <h2>Embedded Content from other Websites</h2>
                <p>
                  Articles on this site may include embedded content (e.g.
                  videos, images, articles, etc.). Embedded content from other
                  websites behaves in the exact same way as if the visitor has
                  visited the other website.
                </p>
                <p>
                  These websites may collect data about you, use cookies, embed
                  additional third-party tracking, and monitor your interaction
                  with that embedded content, including tracking your
                  interaction with the embedded content if you have an account
                  and are logged in to that website.
                </p>
                <h2>Analytics</h2>
                <p>
                  We do not share analytic data with other parties or
                  organisations. We use Analytic data to assess general usage of
                  the site and to improve user experience.
                </p>
                <h2>How long we retain your data</h2>
                <p>
                  If you leave a comment, the comment and its metadata are
                  retained indefinitely. This is so we can recognize and approve
                  any follow-up comments automatically instead of holding them
                  in a moderation queue.
                </p>
                <p>
                  For users that register on our website (if any), we also store
                  the personal information they provide in their user profile.
                  All users can see, edit, or delete their personal information
                  at any time (except they cannot change their username).
                  Website administrators can also see and edit that information.
                </p>
                <h2>What rights you have over your data</h2>
                <p>
                  If you have an account on this site, or have left comments,
                  you can request to receive an exported file of the personal
                  data we hold about you, including any data you have provided
                  to us. You can also request that we erase any personal data we
                  hold about you. This does not include any data we are obliged
                  to keep for administrative, legal, or security purposes.
                </p>
                <h2>Where we send your data</h2>
                <p>
                  Visitor comments may be checked through an automated spam
                  detection service.
                </p>
              </div>
              <div className="col-md-6 ">
                <GatsbyImage
                  image={data.logoimage.childImageSharp.gatsbyImageData}
                  alt="shanti-aid"
                />
                {/* <img src="img/shanti_aid.png" alt="" className="img-fluid rounded-lg d-none d-md-block about-img shadow p-3 mb-5 bg-white rounded"> */}
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    logoimage: file(relativePath: { eq: "shanti_aid.png" }) {
      childImageSharp {
        gatsbyImageData(width: 600)
      }
    }
  }
`
